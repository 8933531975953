'use strict';

jQuery(document).ready(function ($) {

     'use strict';

     // Sakri navigaciju kada se skrola prema dolje, možda ne radi najbolje kad se skrola skroz nazad gore?

     /*    var lastScrollTop = 0;
        var $navbarOutside = $('.navbar-outside');
        var $navbar = $navbarOutside.find('.navbar');
          $navbarOutside.waypoint(function () {
             $navbar.addClass('navbar-hide');
        }, {
             offset: -150
        });
        $(window).scroll(function () {
             var st = $(this).scrollTop();
             if (st < lastScrollTop) {
                  console.log('bilo je manje');
                  $navbar.removeClass('navbar-hide');
             } else {
                  console.log('bilo je veće');
                  $navbar.addClass('navbar-hide');
             }
             lastScrollTop = st;
        });
      */

     // Tražilica u navigaciji - testirat jer pisano napamet

     var $navSearch = $('#nav-search');
     var $navSearchBtn = $('.navbar-search__btn');
     var $navSearchForm = $('#nav-search');
     var $navSearchFormInput = $('#input--search-nav');
     var $navSearchBtnClose = $('#close-btn');

     $('html').on('click', function () {
          $navSearchForm.removeClass('appear');
     });

     $navSearchFormInput.on('click', function (e) {
          e.stopPropagation();
     });

     $navSearchBtn.on('click', function (e) {
          e.stopPropagation();
          $navSearchForm.toggleClass('appear');
          navInputSetFocus();
     });

     // Zatvara tražilicu na mobitelima
     $navSearchBtnClose.on('click', function (e) {
          e.preventDefault();
          e.stopPropagation();
          $navSearchForm.toggleClass('appear');
     });

     // Hack za focus na input
     function navInputSetFocus() {
          setTimeout(function () {
               $navSearchFormInput.focus();
          }, 50);
     }

     $navSearchFormInput.keypress(function (e) {
          if (e.which == 13) {
               $navSearch.submit();
               return false;
          }
     });

     // Animira element kada uđe u ekran

     /*	$(window).scroll(function() {
     		var $animateImgs = $('.imgwrapper').find('img');
     		$animateImgs.each(function() {
     			var $this = $(this);
     			if ($this.is(':in-viewport')) {
     				$this.addClass('appeared');
     			}
     		});
     	});
     	*/

     // Galerija 

     /*      var $fancybox = $('a.fancybox');
          if ($fancybox.length) {
               $('a.fancybox').fancybox({
                    'transitionIn': 'elastic',
                    'transitionOut': 'elastic',
                    'speedIn': 600,
                    'speedOut': 200,
                    'overlayShow': false,
                    'padding': 0,
                    'margin': 10,
                          'tpl': {
                            closeBtn : '<a title="Zatvori" class="fancybox-item fancybox-close" href="javascript:;">x</a>',
                            next     : '<a title="Sljedeći" class="fancybox-nav fancybox-next" href="javascript:;"><</a>',
                            prev     : '<a title="Prethodna" class="fancybox-nav fancybox-prev" href="javascript:;">></a>'
                       }
               });
          } */

     // Anchor link smooth scroll

     /*      $('a[href*=\\#]').on('click', function (e) {
               e.preventDefault();
               // Ubaci anchor ID u URL
               history.pushState({}, '', this.href);
               $('html,body').animate({
                    scrollTop: $(this.hash).offset().top
               }, 500);
          });
      */

     var $kerefeke = $('.kerefeke--pages');
     if ($kerefeke.length) {
          $kerefeke.height($(document).height());
     }

     // Cookie notice settings

     if (Cookies.get('cookie-notice') !== 'Accepted') {
          var $cookieWrapper = $('#bideja-cookie-notice');
          var $cookieBtn = $cookieWrapper.find('#accept-cookie');
          $cookieWrapper.removeClass('bi-remove');
          $cookieBtn.click(function () {
               // Expire je na 5 godina
               Cookies.set('cookie-notice', 'Accepted', {
                    expires: 365 * 5
               });
               // Makni fadeanjem
               $cookieWrapper.fadeOut(300, function () {
                    $(this).remove();
               });
          });
     }

     var $mailchimpInput = $('#js-mailchimp-email');
     function changeEmailPlaceholder() {
          if ($mailchimpInput.length) {
               if ($(window).width() < 405) {
                    $mailchimpInput.attr('placeholder', 'Email');
               } else {
                    $mailchimpInput.attr('placeholder', 'Vaša email adresa');
               }
          }
     }

     changeEmailPlaceholder();
     $(window).on('resize', function () {
          changeEmailPlaceholder();
     });

     // Anchor scroll, radi push anchor to state
     // <div id="o-nama" class="anchor">

     /*var currentHash = "#o-nama";
     $(document).scroll(function () {
     	$('.bi-anchor').each(function () {
     		var top = window.pageYOffset;
     		var distance = top - $(this).offset().top;
     		var hash_name = $(this).attr('id');
     		if (distance < 30 && distance > -30 && currentHash != hash_name) {
     			history.pushState({}, "", '#' + hash_name);
     			currentHash = hash_name;
     		}
     	});
     });*/
});